var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-btn',{attrs:{"color":"primary","to":{name: 'Create project'}}},[_vm._v("Nowy projekt")]),_c('v-spacer'),_c('v-text-field',{staticClass:"shrink",attrs:{"append-icon":"mdi-magnify","label":"Wyszukaj","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"search":_vm.search,"headers":_vm.headers,"items":_vm.projects},scopedSlots:_vm._u([{key:"item.bidding",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.bidding ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item.bidding ? 'On' : 'Off')+" ")])]}},{key:"item.senutoRaport",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.senutoRaport ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item.senutoRaport ? 'On' : 'Off')+" ")])]}},{key:"item.searchNodeRaport",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.searchNodeRaport ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item.searchNodeRaport ? 'On' : 'Off')+" ")])]}},{key:"item.exactagRaport",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.exactagRaport ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item.exactagRaport ? 'On' : 'Off')+" ")])]}},{key:"item.tradeWatchRaport",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.tradeWatchRaport ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item.tradeWatchRaport ? 'On' : 'Off')+" ")])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"15"},on:{"click":function($event){return _vm.editProject(item.id)}}},on),[_vm._v("mdi-pencil")])]}}],null,true)}),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card>
    <v-card-title>
      <v-btn color="primary" :to="{name: 'Create project'}">Nowy projekt</v-btn>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        class="shrink"
        append-icon="mdi-magnify"
        label="Wyszukaj"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :loading="loading"
      :search="search"
      :headers="headers"
      :items="projects"
      class="elevation-1"
    >
      <template v-slot:[`item.bidding`]="{item}">
        <v-chip :color="item.bidding ? 'success' : 'error'">
          {{ item.bidding ? 'On' : 'Off' }}
        </v-chip>
      </template>
      <template v-slot:[`item.senutoRaport`]="{item}">
        <v-chip :color="item.senutoRaport ? 'success' : 'error'">
          {{ item.senutoRaport ? 'On' : 'Off' }}
        </v-chip>
      </template>
      <template v-slot:[`item.searchNodeRaport`]="{item}">
        <v-chip :color="item.searchNodeRaport ? 'success' : 'error'">
          {{ item.searchNodeRaport ? 'On' : 'Off' }}
        </v-chip>
      </template>
      <template v-slot:[`item.exactagRaport`]="{item}">
        <v-chip :color="item.exactagRaport ? 'success' : 'error'">
          {{ item.exactagRaport ? 'On' : 'Off' }}
        </v-chip>
      </template>
      <template v-slot:[`item.tradeWatchRaport`]="{item}">
        <v-chip :color="item.tradeWatchRaport ? 'success' : 'error'">
          {{ item.tradeWatchRaport ? 'On' : 'Off' }}
        </v-chip>
      </template>
      <template v-slot:[`item.edit`]="{item, on}">
        <v-icon v-on="on" size="15" @click="editProject(item.id)">mdi-pencil</v-icon>
      </template>
    </v-data-table>
    <router-view />
  </v-card>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
  name: 'ProjectList',
  data() {
    return {
      search: '',
      loading: false,
      headers: [
        {text: 'Nazwa projektu', value: 'name'},
        {text: 'Domena', value: 'domain'},
        {text: 'Licytacja', value: 'bidding'},
        {text: 'Senuto', value: 'senutoRaport'},
        {text: 'SearchNode', value: 'searchNodeRaport'},
        {text: 'Exactag', value: 'exactagRaport'},
        {text: 'TradeWatch', value: 'tradeWatchRaport'},
        {text: 'Edycja', value: 'edit'},
      ],
    }
  },
  computed: {
    ...mapState({
      projects: state => state.projects.projectList,
    }),
  },
  mounted() {
    this.fetchItems()
  },
  methods: {
    ...mapActions(['getProjectList']),
    async fetchItems() {
      this.loading = true
      await this.getProjectList()
      this.loading = false
    },
    editProject(id) {
      this.$router.push({name: 'Edit project', params: {project_id: id}})
    },
  },
}
</script>

<style scoped></style>
